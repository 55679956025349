import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const FormAdditionalInformation = (props) => {
    const handleSubmitForm = (e) => {
        e.preventDefault();
        console.log("holita");
        props.handleOnClickNext()
    }
    return (
        <div>
            <Formik
                initialValues={{
                    phoneNumber: "",
                    email: "",
                }}
                validationSchema={Yup.object().shape({
                    phoneNumber: Yup.string()
                        .min(10, "Your phone number is too short")
                        .required("Please enter your phone number"),
                    email: Yup.string()
                        .email("The email is incorrect")
                        .required("Please enter your email"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    console.log(values);
                    setSubmitting(true);
                    props.setPhoneNumber(values.phoneNumber)
                    props.setEmailAddress(values.email)
                    props.handleOnClickNext();
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    isSubmitting,
                    validating,
                    valid,
                }) => {
                    return (
                        <>
                            <Form className="flex mt-5 flex-col items-center " name="contact" method="post" onSubmit={handleSubmit}>
                                <div className="flex sm:items-center items-center" htmlFor="phoneNumber">
                                    <p className="block p-3">Celular</p>
                                    <Field
                                        className="focus:outline-none w-48 focus:ring focus:border-blue-300 bg-purple-white shadow rounded border-0 p-3 items-center"
                                        type="number"
                                        name="phoneNumber"
                                        autoComplete="name"
                                        placeholder="your phone number"
                                    />
                                </div>
                                {errors.phoneNumber && touched.phoneNumber && <p className="text-red-500">{errors.phoneNumber}</p>}
                                <div className="flex sm:items-center mt-5  items-center" htmlFor="email">
                                    <p className="block p-3">Email</p>
                                    <Field
                                        className="focus:outline-none w-48 focus:ring focus:border-blue-300 bg-purple-white shadow rounded border-0 p-3 items-center"
                                        type="email"
                                        name="email"
                                        autoComplete="email"
                                        placeholder="your email"
                                    />
                                </div>
                                <ErrorMessage name="email">{(msg) => <p className="text-red-500">{msg}</p>}</ErrorMessage>

                                <button className="bg-positive text-white font-bold py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline"
                                    type="submit"
                                    value="Siguiente"
                                // disabled={!valid || isSubmitting}
                                >

                                    Siguiente
                                </button>
                            </Form>
                        </>
                    );
                }}
            </Formik>
        </div>
    );
}

export default FormAdditionalInformation;
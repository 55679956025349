import React, { Fragment } from "react";

const InitialInformation = (props) => {

  // const handleChange = (value) => {
  //   console.log(value);
  //   props.dispatch({
  //     type: "documentType",
  //     value: value,
  //   });
  //   props.handleOnClickNext();
  // };

  return (
    <Fragment>
      <div className="w-full flex flex-col align-center justify-between flex-grow fade-in">
        <h1 className="block text-fna font-bold text-xl font-bold mb-2 md:text-2xl lg:pt-8">Bienvenido</h1>
        <p className="text-left text-base md:text-lg md:pl-8 text-gray-900 font-bold">
          Bienvenido al portal de validación de identidad de Newmun, a continuación te pediremos:
        </p>
        <ul className="md:text-base md:text-lg pl-4 mt-4 md:self-center list-decimal text-left text-gray-900">
          <li className="text-base md:text-lg">Tu documento de identificación a la mano</li>
          <li className="text-base md:text-lg">Realizaremos un video de tu rostro</li>
        </ul>
        <p className="mt-4 text-left text-base md:text-lg md:pl-8 text-gray-900"><strong>Nota:</strong> Es necesario tomar las fotos y el video en un lugar con buena iluminación.</p>
        <input
          className="fna-color w-40 self-center py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline cursor-pointer"
          type="button"
          value="Iniciar"
          onClick={() => { props.handleOnClickNext() }}
        />
      </div>
      {/* <div>
        <input
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline"
          type="button"
          value="Next"
          onClick={() => { props.handleOnClickNext() }}
        />
      </div> */}
    </Fragment>
  );
};

export default InitialInformation;
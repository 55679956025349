import React, { Fragment, useState, useRef } from "react";
import FormAdditionalInformation from "./../FormAdditionalInformation";
import {
    AlertDialog,
    AlertDialogLabel,
    AlertDialogDescription,
    AlertDialogOverlay,
    AlertDialogContent,
} from "@reach/alert-dialog";

const AdditionalInformation = (props) => {

    // const handleChange = (value) => {
    //   console.log(value);
    //   props.dispatch({
    //     type: "documentType",
    //     value: value,
    //   });
    //   props.handleOnClickNext();
    // };
    const [showDialog, setShowDialog] = useState(false)
    const cancelRef = React.useRef();
    const open = () => setShowDialog(true);
    const close = () => setShowDialog(false);

    const openPopUp = (e) => {
        e.preventDefault();
        toggleModal()
    }

    const closePopUp = (e) => {
        e.preventDefault();
        toggleModal()
    }

    const toggleModal = () => {
        const body = document.querySelector('body')
        const modal = document.querySelector('.modal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
        body.classList.toggle('modal-active')
    }

    return (
        <Fragment>
            <div className="w-full flex flex-col align-center justify-between flex-grow">
                <h1 className="text-lg mb-2 font-bold">Información Personal</h1>
                <div>
                    <p>
                        Por favor ingrese su número de teléfono y su dirección de correo electrónico.
                    </p>

                    <FormAdditionalInformation
                        setPhoneNumber={props.setPhoneNumber}
                        setEmailAddress={props.setEmailAddress}
                        handleOnClickNext={props.handleOnClickNext}
                    />
                    <a onClick={openPopUp} className="modal-open no-underline hover:underline text-purple-700 text-sm text-opacity-75">Privacidad de información</a>
                </div>

                {/* <!--Modal--> */}
                <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center">
                    <div onClick={closePopUp} className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>

                    <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">


                        {/* <!-- Add margin if you want to see some of the overlay behind the modal--> */}
                        <div className="modal-content py-4 text-left px-6">
                            {/* <!--Title--> */}
                            <div className="flex justify-between items-center pb-3">
                                <p className="text-lg font-bold">Trata de información</p>
                                <div onClick={closePopUp} className="modal-close cursor-pointer z-50">
                                    <svg className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                                    </svg>
                                </div>
                            </div>

                            {/* <!--Body--> */}
                            <p className="text-xs">Yo _______________________________________ identificado con el documento de identidad ____ Número ___________________, expedido en __________________ obrando en nombre propio o en calidad de Representante Legal de la sociedad ___________________________ identificada con NIT ________________________, en mi calidad de titular de la información, actuando libre y voluntariamente, autorizo de manera expresa e irrevocable XXXX a consultar o en su defecto reportar mi información ante centrales de riesgo. Conozco que el alcance de esta autorización implica que el comportamiento frente a mis obligaciones serán registrados con el objeto de suministrar información suficiente y adecuada al mercado sobre el estado de mis obligaciones comerciales, financieras, crediticias, de servicios; podrán conocer esta información de conformidad con la legislación y jurisprudencia aplicable, la información podrá ser igualmente utilizada para efectos estadísticos.

                                Mis derechos y obligaciones, así como la permanencia de la información en las bases de datos corresponden a lo determinando por el procedimiento jurídico aplicable del cual por ser de carácter público estoy enterado.

                                En caso que en el futuro, el autorizado en este documento efectúe, a favor de un tercero una venta de cartera o una cesión o cualquier título de las obligaciones a mi cargo, los efectos de esta autorización se extenderán a este mismo término y condiciones.

                                Así mismo, autorizo a la central de información a que en su calidad de operador, ponga mi información a disposición de otros operadores nacionales o extranjeros, en los términos que establezca la ley, siempre y cuando su objeto sea similar al establecido.</p>

                            {/* <!--Footer--> */}
                            {/* <div className="flex justify-end pt-2">
                                <button onClick={closePopUp} className="modal-close px-4 bg-indigo-500 p-3 rounded-lg text-white hover:bg-indigo-400">Close</button>
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>
            {/* <div>
        <input
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline"
          type="button"
          value="Next"
          onClick={() => { props.handleOnClickNext() }}
        />
      </div> */}
        </Fragment>
    );
};

export default AdditionalInformation;
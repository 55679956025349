import React, { Fragment, useEffect, useState } from "react";
import LoadingImg from "../../assets/images/load_init.gif";
import CheckMark from "../../assets/images/check-mark.png";
import CancelImg from "../../assets/images/cancel.png";
import { sendMessage } from "./../../Utils";
import axios from "axios";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";

const findTerm = (str, term) => {
  if (str.includes(term)) {
    return str;
  }
};

function ErrorList(props) {
  const errores = props.errores;
  const listItems = errores.map((err) => {
    switch (err.code) {
      case findTerm(err.code, "identicalImages"):
        err.code = "Imágenes idénticas";
        break;
      case findTerm(err.code, "badText"):
        err.code = "Mal texto en el documento";
        break;
      case findTerm(err.code, "blurryText"):
        err.code = "Texto borroso";
        break;
      case findTerm(err.code, "smallImageSize"):
        err.code = "Imagen demasiado pequeña";
        break;
      case findTerm(err.code, "noFace"):
        err.code = "No hay ningún rostro en el documento";
        break;
      case findTerm(err.code, "noDocument"):
        err.code = "No hay ningún documento en la foto";
        break;
      case findTerm(err.code, "missingFields"):
        err.code = "Campos faltantes en el documento";
        break;
      case findTerm(err.code, "internalError"):
        err.code = "Fallo interno del servidor";
        break;
      case findTerm(err.code, "multipleFaces"):
        err.code = "Múltiples rostros capturados simultaneamente en el video";
        break;
      case findTerm(err.code, "noFace"):
        err.code =
          "No detectamos ningún rostro en el video. Recuerde grabar el video-selfie sin tapabocas";
        break;
      default:
        break;
    }
    return (
      <li className="text-lg md:text-xl" key={err.documentError}>
        {err.documentError} - {err.code}
      </li>
    );
  });
  return <ul className="text-lg mt-2 md:text-xl">{listItems}</ul>;
}

const FinalStep = (props) => {
  const [video, setVideo] = useState();
  const [fetchProgress, setFetchProgress] = useState(0);
  const [isLoading, setIsloading] = useState();
  const [fetchResult, setFetchResult] = useState([]);
  const [hadError, setHadError] = useState(false);

  const refreshPage = (e) => {
    e.preventDefault();
    window.location.replace(
      window.location.pathname + window.location.search + window.location.hash
    );
    // window.location.assign(window.location.href)
  };

  const closePage = (e) => {
    e.preventDefault();
    window.close();
  };

  useEffect(() => {
    setIsloading(true);
    var files = new FormData();
    setVideo([props.state.selfieVideo]);

    files.append("document1", props.state.frontDocument[0], "front.jpg");
    if (props.state.documentType !== "passport") {
      files.append("document2", props.state.backDocument[0], "back.jpg");
    }
    if (props.canVideo) {
      files.append("video", props.state.selfieVideo[0], "video.mp4");
    }

    if (props.emailAddress) {
      files.append("email_address", props.emailAddress);
    }

    if (props.emailAddress) {
      files.append("phone_number", props.phoneNumber);
    }

    files.append("user_id", props.userId);
    files.append("contract_id", props.contractId);
    files.append("file_type", props.state.documentType);
    files.append("country", props.state.country);

    if (props.phoneNumberEnc) {
      files.append("phone_number_enc", props.phoneNumberEnc);
    }

    const URL_VALIDATION =
      "https://becomedigital-api.azurewebsites.net/api/v1/identity";

    setTimeout(() => {
      axios
        .post(URL_VALIDATION, files, {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
          onUploadProgress: (p) => {
            var percentage = 0;
            percentage = Math.floor((p.loaded * 100) / p.total);
            setFetchProgress(percentage);
          },
        })
        .then((response) => {
          var fetchData = response;
          setFetchResult(fetchData.data);
          sendMessage("fetchResult", { data: fetchData.data });
          setIsloading(false);
        })
        .catch((error) => {
          setHadError(true);
        });
    }, 1000);
  }, [props]);

  const handleFinishValidation = (e) => {
    e.preventDefault();
    sendMessage("userFinishedSdk", { data: fetchResult });
    window.location.assign("https://app.newmun.co/status-kyc");
  };

  return (
    <Fragment>
      {isLoading ?
        (
          <div className="w-full h-full flex flex-col align-center justify-between md:p-8">
            <div className="w-full h-full flex align-center justify-center">
              <svg className="animate-spin object-contain w-1/4 h-1/4 lg:w-1/2 lg:h-1/2 self-center text-fna" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
            <p className="text-center w-full mt-4 text-lg md:text-xl text-gray-900">Estamos procesando sus datos...</p>
          </div>
        ) :
        (<div className="w-full h-full flex flex-col align-center justify-between md:p-8 fade-in text-gray-900">
          <p className="text-center text-2xl pt-12">Fin de la verificación</p>
          {fetchResult.message === "El recurso fue creado" &&
            (
              <>
                <p className="text-lg md:text-xl">
                  Haz finalizado, ya puedes cerrar esta página dando clic en Finalizar.
                </p>
                <img src={CheckMark} alt="Check Mark" className="w-24 h-24 self-center" />
                <input
                  className="fna-color py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline cursor-pointer"
                  type="button"
                  value="Finalizar"
                  onClick={handleFinishValidation}
                />
              </>
            )}

          {fetchResult.message === "Ya existe un registro con el mismo ID para esta compañia" &&
            (
              <>
                <img src={CancelImg} alt="Cancel" className="w-24 h-24 self-center" />
                <p className="text-center text-lg md:text-xl">Ya existe una validación de identidad para este usuario.</p>
              </>
            )}

          {fetchResult.message === "Intentos excedidos" &&
            (
              <>
                <img src={CancelImg} alt="Cancel" className="w-24 h-24 self-center" />
                <p className="text-center text-lg md:text-xl">Se ha sobre pasado el número de intentos permitidos.</p>
              </>
            )}

          {fetchResult.message === "Hubo un problema con el envio de los archivos" &&
            (
              <>
                <img src={CancelImg} alt="Cancel" className="w-24 h-24 self-center" />

                <ErrorList errores={fetchResult.error} />

                <p className="text-center mt-2 text-lg md:text-xl">Hubo en error con el envío de sus documentos, por favor reintente la validación.</p>

                <input
                  className="fna-color-secoundary font-bold py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline cursor-pointer"
                  type="button"
                  value="Reintentar"
                  onClick={refreshPage}
                />
              </>
            )}

          {hadError ?
            (
              <>
                <img src={CancelImg} alt="Cancel" className="w-24 h-24 self-center" />
                <p className="text-center text-lg md:text-xl">Hemos perdido la conexión contigo</p>
                <p className="text-center text-lg md:text-xl">Tenemos dificultades para comunicarnos con nuestros servidores</p>
                <input
                  className="fna-color-secoundary font-bold py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline cursor-pointer"
                  type="button"
                  value="Volver a intentar"
                  onClick={refreshPage}
                />
              </>
            ) : null}
        </div>
      )}
    </Fragment>
  );
};

export default FinalStep;

import React, { useEffect } from "react";
import Webcam from "react-webcam";

import { sendMessage } from "../Utils";

const WebcamStreamCapture = (props) => {
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);
  const [videoDuration, setVideoDuration] = React.useState(0);
  const [errorVideo, setErrorVideo] = React.useState(false);

  useEffect(() => {
    var recorder = document.getElementById("recorder");

    var video = document.createElement("video");

    recorder.addEventListener("change", function (e) {
      var file = e.target.files[0];
      let sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      video.preload = "metadata";

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        if (this.duration > 7 || sizeInMB > 45) {
          setErrorVideo(true);
        } else {
          setErrorVideo(false);
          props.dispatch({
            type: "selfieVideo",
            value: [file],
          });
          props.setVideo([file]);

          sendMessage('selfieVideo', { "data": 'step completed' })

          props.handleOnClickNext();
        }
      };

      video.src = URL.createObjectURL(file);
    });
  }, []);
  const handleStartCaptureClick = React.useCallback(
    (e) => {
      e.preventDefault();
      setCapturing(true);
      console.log(webcamRef.current.stream);
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: "video/webm",
      });
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );
      mediaRecorderRef.current.start();
    },
    [webcamRef, setCapturing, mediaRecorderRef]
  );

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(
    (e) => {
      e.preventDefault();
      mediaRecorderRef.current.stop();
      setCapturing(false);
    },
    [mediaRecorderRef, webcamRef, setCapturing, recordedChunks]
  );

  const handleDownload = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/mp4",
      });
      if (recordedChunks.length) {
        console.log("hola desde aquí");
        props.dispatch({
          type: "selfieVideo",
          value: recordedChunks,
        });
      }
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "react-webcam-stream-capture.mp4";
      a.click();
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);

  return (
    <>
      {errorVideo && (
        <div class="bg-red-400 text-white font-small py-2">
          <p>Por favor, grabe un video con las siguientes caracteristicas:</p>
          <ul>
            <li>Duración: Igual o menor a 7s</li>
            <li>Tamaño: Maximo 45MB</li>
          </ul>
        </div>
      )}
      <div className="relative w-64 mt-4 mb-4 self-center">
        <label className="fna-color font-bold py-2 px-4 w-full inline-flex items-center rounded text-center">
          <span className="text-base leading-normal text-center flex-grow">
            Video haciendo dos circulos con tu cabeza
          </span>
          <input
            id="recorder"
            type="file"
            accept="video/*"
            capture="user"
            className="cursor-pointer hidden"
          ></input>
        </label>
      </div>
    </>
  );
};

export default WebcamStreamCapture;
